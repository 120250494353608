export function getEnvVar(variableName, clientValue, defaultValue = null) {
	// Try to get the normal version of the variable
	let value = process.env[variableName];

	// If still not found, use the client/default value
	if (!value && clientValue && clientValue != "") {
		if(clientValue && clientValue != "") {
			value = clientValue;
		} else {
			value = defaultValue;
		}
	}

	return value;
}

export const APP_SITE: string = getEnvVar("APP_SITE", process.env.NEXT_PUBLIC_APP_SITE);
export const DEFAULT_PAGE = APP_SITE == "consumer" ? "" : "home";
export const APP_URL = getEnvVar("APP_URL", process.env.NEXT_PUBLIC_APP_URL) || "";
export const GLOBAL_PAGE_SITES = ["jobs"];
export const JOB_TERMS: string[] = [
	"vacancies",
	"vacatures",
	"jobs",
	"jobangebot",
	"poste-vacant",
];
