import { API_URL } from "../config";
import { getMarketandLang } from "./MarketandLanguage";
import { parseUrlStruct } from "./parseURL";

const SlugEndpointMap: Record<string, string> = {
	deeplink: `${API_URL}/api/deeplinks/get_template`,
};

export function getEndpointURL(parsed_url: parseUrlStruct): string {
	const { urlModule, locale, pageSlug, additionQueries } = parsed_url;
	const localization = getMarketandLang(locale);
	const endpoint_url = SlugEndpointMap[pageSlug]
		? `${SlugEndpointMap[pageSlug]}?market=${localization.market}`
		: `${API_URL}/api/apps/${urlModule}/pages/${localization.market}/${pageSlug}/${localization.language}/${additionQueries}`;

	return endpoint_url;
}
