import { unstable_cache } from "next/cache";
import { INGRESS_URL } from "../config";
import { MediaSrc } from "../interfaces/common";
import { flattenObject, setValue } from "./flattenObject";

export const getMediaSrc = (
	media_src: MediaSrc["media_src"],
	mobileView: boolean
) => {
	const desktopSrc = media_src.desktop?.src ?? media_src.desktop.src;
	const mobileSrc = media_src.mobile?.src ?? media_src.mobile.src;
	return media_src && (mobileView && mobileSrc ? mobileSrc : desktopSrc);
};


export const imageMeta = async (pageJson) => {
	let out = pageJson;
	let mediaManifest: any = await unstable_cache(async () => {
		const response = await fetch(`${INGRESS_URL}/api/ingress-server/ingress/medias`);
	
		if (!response.ok) {
		  throw new Error('Failed to fetch data');
		}
	
		return response.json();
	  }, [`mediaManifest`], {
		revalidate: 600
	  })();
	if (Array.isArray(mediaManifest.data)) {
		mediaManifest = (mediaManifest?.data || []).reduce((acc, media) => {
			acc[media._id.toString()] = media;
			return acc;
		}, {});
	}
	const flattendPageJson = flattenObject(pageJson);
	let media: any = [];
	let preloadMedia: any = [];
	for (const key in flattendPageJson) {
		if (
			key.endsWith(".src") &&
			flattendPageJson[key] &&
			flattendPageJson[key] != ""
		) {
			const regex = /^[0-9a-fA-F]{24}$/;
			const [id, variant] = flattendPageJson[key].split("@");
			const matchFound = Object.values(mediaManifest).find((media) => {
				return (
					media.filename ==
					flattendPageJson[key].split("/").pop().split(".")[0]
				);
			});
			let currentSrc: any = {
				type: null,
				src: null,
			};
			if (
				regex.test(id) &&
				typeof mediaManifest == "object" &&
				mediaManifest[id]
			) {
				setValue(
					out,
					key.replace(".src", ".type"),
					mediaManifest[id].type
				);
				setValue(
					out,
					key,
					"https://d2jxuf8ovdiw8x.cloudfront.net/" +
						mediaManifest[id].variants[variant].remote_path
				);
				setValue(out, key.replace(".src", ".meta"), {
					blurhash: mediaManifest[id].blurhash,
				});
				currentSrc.type = mediaManifest[id].type;
				currentSrc.src =
					"https://d2jxuf8ovdiw8x.cloudfront.net/" +
					mediaManifest[id].variants[variant].remote_path;
			} else if (matchFound) {
				setValue(out, key.replace(".src", ".type"), matchFound.type);
				setValue(
					out,
					key,
					"https://d2jxuf8ovdiw8x.cloudfront.net/" +
						matchFound.variants.normal.remote_path
				);
				setValue(out, key.replace(".src", ".meta"), {
					blurhash: matchFound.blurhash,
				});
				media.push({
					type: matchFound.type,
					src:
						"https://d2jxuf8ovdiw8x.cloudfront.net/" +
						matchFound.variants.normal.remote_path,
				});
				currentSrc.type = matchFound.type;
				currentSrc.src =
					"https://d2jxuf8ovdiw8x.cloudfront.net/" +
					matchFound.variants.normal.remote_path;
			} else {
				let blurhash =
					"data:image/webp;base64,UklGRjAAAABXRUJQVlA4ICQAAACQAQCdASoEAAIAAgA0JZwAAudYugAA/vShPTMGsRJli3i4AAA=";
				setValue(out, key.replace(".src", ".meta"), {
					blurhash: blurhash,
				});
				currentSrc.type =
					flattendPageJson[key.replace(".src", ".type")];
				currentSrc.src = flattendPageJson[key];
			}
			// add media to preload list
			if (!currentSrc.src.includes("logo") && preloadMedia.length == 0) {
				preloadMedia.push(currentSrc);
			}
		}
	}
	mediaManifest = null;
	out.media_manifest = media;
	out.preload_media = preloadMedia;
	return out;
};